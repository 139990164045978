import React from "react";
import { connect } from "react-redux";
import {
    TDispatchMapper,
    TStateMapper,
} from "tsi-common-react/src/apps/reducers.interfaces";
import { defaultState } from "tsi-common-react/src/apps/reviews/defaults";
import { Dispatchers } from "tsi-common-react/src/apps/reviews/dispatchers";
import { ISortOption } from "tsi-common-react/src/apps/reviews/reducers.interfaces";
import { ReviewSortOption } from "tsi-common-react/src/models/reviews.interfaces";

interface IOwnProps {}

interface IReduxProps {
    sortOptions: ISortOption[];
    selectedSortOption: ReviewSortOption;
}

interface IDispatchProps {
    updateSorting: Dispatchers["updateSorting"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

const SortingSelectorComponent = (props: IProps) => {
    return (
        <div className="reviews-region-sort">
            <div className="review-menu-title caption-light">Sort:</div>
            <select
                className="select select--not-styled select--no-border select--large-down-arrow review-select"
                value={props.selectedSortOption}
                onChange={(event) => {
                    props.updateSorting(
                        event.currentTarget.value as ReviewSortOption,
                    );
                }}
            >
                {props.sortOptions.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                        {opt.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        sortOptions: state.data.sortOptions,
        selectedSortOption: state.ui.selectedSortOption,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        updateSorting: dispatchers.updateSorting,
    };
};

export const SortingSelector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SortingSelectorComponent);
