import { format as formatDate } from "date-fns";
import { Dinero } from "dinero.js";
import { EventEmitter } from "events";
import { Key } from "ts-key-enum";
import { ILocationGuessResponse } from "tsi-common-react/src/models/location.interfaces";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
import { getDinero } from "tsi-common-react/src/utils/money";

import shipping from "./shipping";

const MONEY_FORMAT = "$0,0.00";

interface Location extends ILocationGuessResponse {
    city: string;
    region: string;
    state_name: string;
    location_name: string;
    shipping_cost: Dinero;
}

class TransitTimeStore extends EventEmitter {
    public shipDate: string | undefined;
    public arrivalDate: Date | undefined;
    public daysInTransit: number | undefined;
    public userLocation: Location | undefined;

    constructor(shipDate?: string) {
        super();
        this.shipDate = shipDate;
    }

    private readonly _getShipmentArrivalDate = async (
        newLocation: ILocationGuessResponse,
    ): Promise<void> => {
        if (!newLocation.zip) {
            return;
        }
        const resp = await shipping.getShipmentArrivalDate(
            newLocation.zip,
            this.shipDate,
        );
        if (!resp) {
            return;
        }
        const loc: Location = {
            ...newLocation,
            city: resp.city,
            region: resp.state_province_code,
            state_name: resp.state_name,
            location_name: resp.location_name,
            shipping_cost: getDinero(resp.shipping_cost),
        };

        this.arrivalDate = resp.arrives_on;
        this.emit("change:arrivalDate");

        this.daysInTransit = resp.days_in_transit;
        this.emit("change:daysInTransit");

        this.userLocation = loc;
        this.emit("change:userLocation");
    };

    public readonly updateUserLocation = async (
        newLocation: ILocationGuessResponse | null,
    ): Promise<void> => {
        if (!newLocation) {
            return;
        }
        if (this.userLocation && newLocation.zip === this.userLocation.zip) {
            return;
        }
        await this._getShipmentArrivalDate(newLocation);
    };

    public readonly getLocationDisplayName = () => {
        if (this.userLocation && this.userLocation.location_name) {
            return this.userLocation.location_name;
        }
        if (this.userLocation && this.userLocation.zip) {
            return this.userLocation.zip;
        }
        throw new Error("Not enough information provided");
    };

    public readonly getShippingCost = () => {
        // TODO can modify and use shippingCost function at
        // tsi-common-react: utils/format.ts
        if (this.userLocation && this.userLocation.shipping_cost.isZero()) {
            return "FREE";
        }
        if (this.userLocation) {
            return this.userLocation.shipping_cost.toFormat(MONEY_FORMAT);
        }
        throw new Error("Not enough information provided");
    };
}

const newTransitTimeStore = () => {
    const transitTime = new TransitTimeStore();

    // Fill DOM placeholders with the user's location
    document
        .querySelectorAll<HTMLElement>(".l10n-fill-user-location")
        .forEach((elem) => {
            transitTime.on("change:userLocation", () => {
                elem.innerHTML = transitTime.getLocationDisplayName();
            });
        });

    // Fill the shipping cost for the user's location
    document
        .querySelectorAll<HTMLElement>(".l10n-fill-shipping-cost")
        .forEach((elem) => {
            const prefix = elem.dataset.prefix || "";
            const suffix = elem.dataset.suffix || "";
            transitTime.on("change:userLocation", () => {
                const cost = transitTime.getShippingCost();
                elem.innerHTML = `${prefix}${cost}${suffix}`;
            });
        });

    // Fill the shipping cost for the user's location
    document
        .querySelectorAll<HTMLElement>(".l10n-fill-shipping-estimate")
        .forEach((elem) => {
            const prefix = elem.dataset.prefix || "";
            const suffix = elem.dataset.suffix || "";
            transitTime.on("change:userLocation", () => {
                elem.innerHTML = `${prefix}${suffix}`;
            });
        });

    // Fill the date of arrival to the user's location, assuming the package was shipped now.
    document
        .querySelectorAll<HTMLElement>(".l10n-fill-shipping-arrival-date")
        .forEach((elem) => {
            const format = elem.dataset.format || "MMMM do";
            const prefix = elem.dataset.prefix || "";
            transitTime.on("change:arrivalDate", () => {
                if (transitTime.arrivalDate) {
                    elem.innerHTML = `${prefix}${formatDate(
                        transitTime.arrivalDate,
                        format,
                    )}`;
                }
            });
        });

    // Fill the number of days the package will be in transit
    document
        .querySelectorAll<HTMLElement>(".l10n-fill-shipping-days-in-transit")
        .forEach((elem) => {
            transitTime.on("change:daysInTransit", () => {
                if (transitTime.daysInTransit) {
                    elem.innerHTML = `${transitTime.daysInTransit}`;
                }
            });
        });

    return transitTime;
};

const fillPreExistingShipmentPlaceholders = () => {
    // Calculate arrival dates for existing shipments
    document
        .querySelectorAll<HTMLElement>(".l10n-fill-shipment-arrival-date")
        .forEach((elem) => {
            const dest = elem.dataset.destination;
            const format = elem.dataset.format || "MMMM do";
            const shippedOn = formatDate(
                new Date(elem.dataset.shippedOn!),
                "yyyy-MM-dd",
            );
            const transitTime = new TransitTimeStore(shippedOn);
            transitTime.on("change:userLocation", () => {
                if (transitTime.arrivalDate) {
                    elem.innerHTML = formatDate(
                        transitTime.arrivalDate,
                        format,
                    );
                }
            });
            transitTime.updateUserLocation({ zip: dest });
        });
};

const registerEditableLocationFields = (transitTime: TransitTimeStore) => {
    // Handle guessed locations that should be user editable
    document
        .querySelectorAll<HTMLElement>(".l10n-fill-user-location.l10n-editable")
        .forEach((elem) => {
            elem.addEventListener("click", (event) => {
                const inputElem = document.createElement("input");
                inputElem.classList.add("l10n-zip-input");
                inputElem.placeholder = "ZIP CODE";
                inputElem.setAttribute("id", "location-zip-code");
                event.preventDefault();
                if (transitTime.userLocation && transitTime.userLocation.zip) {
                    inputElem.value = transitTime.userLocation.zip;
                }
                const update = async () => {
                    const zip = `${inputElem.value}`;
                    if (zip.length < 5) {
                        return;
                    }
                    inputElem.disabled = true;
                    try {
                        await transitTime.updateUserLocation({ zip: zip });
                        shipping.setClientLocation(zip);
                        inputElem.remove();
                        elem.style.display = "block";
                    } catch (e) {
                        inputElem.disabled = false;
                        inputElem.focus();
                    }
                };
                inputElem.addEventListener("blur", update);
                inputElem.addEventListener("keypress", (e) => {
                    if (e.key === Key.Enter) {
                        update();
                    }
                });
                elem.style.display = "none";
                if (elem.nextSibling) {
                    elem.parentNode?.insertBefore(inputElem, elem.nextSibling);
                } else {
                    elem.parentNode?.appendChild(inputElem);
                }
                inputElem.focus();
            });
        });
};

onDOMContentLoaded.on(async () => {
    const elems = document.querySelectorAll<HTMLElement>("[class*='l10n-']");
    if (!elems.length) {
        return;
    }

    // Fill placeholders for pre-existing shipments
    fillPreExistingShipmentPlaceholders();

    // Build store / event broker for holding location and shipping data
    const transitTime = newTransitTimeStore();

    // Guess the user's location and fill in page templates
    const location = await shipping.getClientLocation();
    transitTime.updateUserLocation(location);

    // Make click-to-edit location fields work
    registerEditableLocationFields(transitTime);
});

onDOMContentLoaded.on(() => {
    const smallPackages = document.querySelectorAll<HTMLElement>(
        ".order-confirmation .shpping-method--small-package",
    );
    const freights = document.querySelectorAll<HTMLElement>(
        ".order-confirmation .shpping-method--freight",
    );
    const numSmallPackagesHolder = document.querySelector<HTMLElement>(
        "#order-confirmation-shipping-method-small-package-holder",
    );
    const numFreightsHolder = document.querySelector<HTMLElement>(
        "#order-confirmation-shipping-method-freight-holder",
    );
    const numOrderSummaryHolder = document.querySelector<HTMLElement>(
        "#order-confirmation-order-summary-holder",
    );
    const getNumPackages = (elements: NodeListOf<HTMLElement>) => {
        return Array.from(elements)
            .map((elem: HTMLElement) => {
                return elem;
            })
            .reduce((num, elem) => {
                return num + Number(elem.dataset.quantity);
            }, 0);
    };
    const numSmallPackages = getNumPackages(smallPackages);
    const numFreights = getNumPackages(freights);

    if (numSmallPackagesHolder) {
        numSmallPackagesHolder.textContent = `${numSmallPackages} ${
            numSmallPackages !== 1 ? "items" : "item"
        }`;
    }
    if (numFreightsHolder) {
        numFreightsHolder.textContent = `${numFreights} ${
            numFreights !== 1 ? "items" : "item"
        }`;
    }
    if (numOrderSummaryHolder) {
        numOrderSummaryHolder.textContent = `${
            numSmallPackages + numFreights
        } ${numSmallPackages + numFreights !== 1 ? "items" : "item"}`;
    }
});
