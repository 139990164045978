import { Dinero } from "dinero.js";
import React from "react";
import { connect } from "react-redux";
import { defaults } from "tsi-common-react/src/apps/checkout/defaults";
import { FinancingModalTriggerBannerMinicart } from "tsi-common-react/src/apps/financing/FinancingModalTrigger";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import { Link } from "tsi-common-react/src/common/Link";
import { IBasket } from "tsi-common-react/src/models/catalogue.interfaces";
import { format } from "tsi-common-react/src/utils/format";
import { getDinero, getMonthlyPrice } from "tsi-common-react/src/utils/money";
import { urls } from "tsi-common-react/src/utils/urls";
import { t } from "ttag";

interface IOwnProps {
    closeModal: () => void;
}

interface IReduxProps {
    basket: IBasket | null;
}

interface IDispatchProps {}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    termLength: string;
    termSuperscript: string;
    termThreshold: string;
}

// TODO: Consolidate Financing Trigger components into one
class FinancingModalTriggerMinicartComponent extends React.Component<
    IProps,
    IState
> {
    public state: IState = {
        termLength: "1",
        termSuperscript: "",
        termThreshold: "",
    };

    componentDidMount() {
        this.calcFinancePlan();
    }

    private calcFinancePlan() {
        const total = this.props.basket
            ? parseFloat(this.props.basket.total_excl_tax)
            : 0;

        const plansHTML = Array.from<HTMLElement>(
            document.querySelectorAll(".financing-plan-table__plan"),
        );
        const availablePlans = plansHTML.filter((plan) => {
            return total >= parseFloat(plan.dataset.threshold || "");
        });

        if (availablePlans.length) {
            const bestPlanLength =
                availablePlans[availablePlans.length - 1].dataset.length;
            const bestPlanSuperscript =
                availablePlans[availablePlans.length - 1].dataset.superscript;
            const bestPlanThreshold =
                availablePlans[availablePlans.length - 1].dataset.threshold;

            this.setState({
                termLength: bestPlanLength ? bestPlanLength : "1",
                termSuperscript: bestPlanSuperscript ? bestPlanSuperscript : "",
                termThreshold: bestPlanThreshold ? bestPlanThreshold : "",
            });
        }
    }

    render() {
        const pricePerMonth: Dinero = this.props.basket
            ? getMonthlyPrice(
                  getDinero(this.props.basket.total_excl_tax),
                  parseInt(this.state.termLength, 10),
              )
            : getDinero(0.0);
        const showFinancing: boolean =
            this.props.basket && this.state.termThreshold
                ? parseFloat(this.props.basket.total_excl_tax) >=
                  parseFloat(this.state.termThreshold)
                : false;
        return (
            <div className="basket-popover-financing">
                {showFinancing && (
                    <>
                        <div className="basket-popover-financing__title">
                            {t`or Special Financing`}
                        </div>
                        <div className="basket-popover-financing__terms">
                            {format.money(pricePerMonth)}/{t`mo`}
                            <sup>
                                <Link href={urls.pageURL("finance-link")}>
                                    2
                                </Link>
                            </sup>
                            <span className="basket-popover-financing__terms--medium">
                                {t`for`}
                            </span>
                            {t`${this.state.termLength} Months`}
                            <sup>
                                <Link href={urls.pageURL("finance-link")}>
                                    {this.state.termSuperscript}
                                </Link>
                            </sup>
                        </div>
                    </>
                )}
                <FinancingModalTriggerBannerMinicart
                    closeModal={this.props.closeModal}
                />
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout || defaults;
    return {
        ...ownProps,
        basket: state.data.basket,
    };
};

export const FinancingModalTriggerMinicart = connect(mapStateToProps)(
    FinancingModalTriggerMinicartComponent,
);
