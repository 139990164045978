import React from "react";
import { connect } from "react-redux";
import {
    TDispatchMapper,
    TStateMapper,
} from "tsi-common-react/src/apps/reducers.interfaces";
import {
    IReviewsAppDispatchProps,
    IReviewsAppOwnProps,
    IReviewsAppReduxProps,
    ReviewsAppContainer,
} from "tsi-common-react/src/apps/reviews/containers/_base";
import { defaultState } from "tsi-common-react/src/apps/reviews/defaults";
import { Dispatchers } from "tsi-common-react/src/apps/reviews/dispatchers";
import {
    getParamsFromFacetValues,
    updateQueryParams,
} from "tsi-common-react/src/apps/reviews/history";
import { notEmpty, unique } from "tsi-common-react/src/utils/functional";

import { ReviewsViewer as ReviewsViewerComponent } from "../components/ReviewsViewer";

interface IOwnProps extends IReviewsAppOwnProps {}

interface IReduxProps extends IReviewsAppReduxProps {}

interface IDispatchProps extends IReviewsAppDispatchProps {}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

class ReviewsViewerContainer extends ReviewsAppContainer<IProps> {
    static defaultProps: Partial<IProps> = {
        pageSize: 6,
    };

    componentDidMount() {
        super.componentDidMount();
        // Select all the product types
        this.updateProductTypeIDs();
        // Update query string to match the current facet selections
        this.pushQueryStringUpdate();
    }

    componentDidUpdate(prevProps: IProps) {
        super.componentDidUpdate(prevProps);
        // Select all the product types
        this.updateProductTypeIDs();
        // Update query string to match the current facet selections
        this.pushQueryStringUpdate();
    }

    private updateProductTypeIDs() {
        const productTypeIDs = this.props.products
            .map((p) => p.product_type)
            .filter(notEmpty)
            .filter(unique);
        if (
            JSON.stringify(this.props.productTypeIDs) !==
            JSON.stringify(productTypeIDs)
        ) {
            this.props.dispatchers.setProductTypes(productTypeIDs);
        }
    }

    private pushQueryStringUpdate() {
        const params = getParamsFromFacetValues(
            this.props.facetValues,
            this.props.productTypeIDs,
        );
        updateQueryParams(params);
    }

    protected buildContent() {
        return (
            <ReviewsViewerComponent
                onLoadMore={this.onLoadMore}
                isLoading={this.state.isLoadingMore}
            />
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        // Direct Props
        ...ownProps,
        // Base Redux Data
        loadedInitialProducts: state.data.loadedInitialProducts,
        loadedInitialReviews: state.data.loadedInitialReviews,
        products: state.data.products,
        productTypeIDs: state.ui.productTypeIDs,
        selectedSortOption: state.ui.selectedSortOption,
        reviews: state.data.reviews,
        facets: state.data.facets,
        facetValues: state.ui.facetValues,
        page: state.ui.page,
        hasMore: state.ui.hasMore,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const ReviewsViewer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReviewsViewerContainer);
