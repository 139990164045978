import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";
import { CSSTransition } from "react-transition-group";
import { t } from "ttag";

import iconXClose from "../../img/icons/x-close.svg";
import { signupForEmailOffers } from "../api/user";
import { IStickyEmailBanner } from "../models/sticky-email-banner.interfaces";
import { focusElement, trapFocus } from "../utils/keyboardFocus";
import { RichText } from "./RichText";

const TRUE = "true";

interface IProps {
    bannerData: IStickyEmailBanner;
}

interface IState {
    showBanner: boolean;
    submitted: boolean;
    closeRequests: number;
    showAgainDatetime: number | null;
    currentDatetime: number | null;
    timer: number | null;
    hasBeenSubmittedOrClosed: boolean;
    email: string;
    errors: string[];
}

export class StickyEmailBanner extends React.Component<IProps, IState> {
    private localStorageKeyClosed = "email-signup-modal-closed";
    private localStorageKeyCloseRequests = "email-signup-modal-closeRequests";
    private localStorageKeyShowAgainDatetime =
        "email-signup-modal-showAgainDatetime";
    private currentDatetimeID: ReturnType<typeof setInterval> = setInterval(
        () => {},
    );
    private origFocusedElem: Element | null = null;
    private readonly emailInputElem = React.createRef<HTMLInputElement>();

    public state: IState = {
        showBanner: false,
        submitted: false,
        closeRequests: 0,
        showAgainDatetime: null,
        currentDatetime: null,
        timer: null,
        hasBeenSubmittedOrClosed: this.getHasBeenSubmittedOrClosed(),
        email: "",
        errors: [],
    };

    componentDidMount() {
        const scrollDistanceUntilAppearance = 300;
        const startingScrollY = window.scrollY;
        let currentScrollY = null;

        window.addEventListener("scroll", () => {
            if (!this.getHasBeenSubmittedOrClosed()) {
                currentScrollY = window.scrollY;
                if (
                    Math.abs(startingScrollY - currentScrollY) >
                    scrollDistanceUntilAppearance
                ) {
                    if (!this.state.showBanner) {
                        this.setState({
                            showBanner: true,
                        });
                    }
                }
            }
        });
        this.currentDatetimeID = setInterval(() => {
            this.setState({
                currentDatetime: new Date().getTime(),
            });
        }, 2000);
    }

    componentWillUnmount() {
        document.body.classList.remove("sticky-email-banner--closed");
        document.body.classList.remove("sticky-email-banner--open");
    }

    componentDidUpdate() {
        if (this.state.hasBeenSubmittedOrClosed) {
            clearInterval(this.currentDatetimeID);
            return;
        }
        if (this.state.closeRequests >= this.props.bannerData.maxCloses) {
            this.setHasBeenSubmittedOrClosed();
            this.setState({
                hasBeenSubmittedOrClosed: true,
            });
        }
    }

    private getHasBeenSubmittedOrClosed() {
        try {
            return localStorage[this.localStorageKeyClosed] === TRUE;
        } catch (e) {
            console.error(e);
        }
        return false;
    }

    private setHasBeenSubmittedOrClosed() {
        try {
            localStorage[this.localStorageKeyClosed] = TRUE;
        } catch (e) {
            console.error(e);
        }
    }

    private closeModal() {
        this.setState({
            showBanner: false,
        });
        // restore focus to originally focused element
        if (this.origFocusedElem) {
            (this.origFocusedElem as HTMLElement).focus();
        }
    }

    private onAfterOpen() {
        // save currently focused element before changing it
        this.origFocusedElem = document.activeElement;
        const emailSubscriptionModal = document.querySelector(
            "#email-subscription-modal",
        ) as HTMLElement;
        if (this.emailInputElem.current) {
            focusElement(this.emailInputElem.current);
        }
        if (emailSubscriptionModal) {
            trapFocus(emailSubscriptionModal);
        }
    }

    private onAfterClose() {
        const milliseconds = this.props.bannerData.waitTimeMinutes * 60 * 1000;
        const currentDateTime = new Date();
        const showAgainDatetime = new Date(
            currentDateTime.getTime() + milliseconds,
        );
        const closeRequests =
            localStorage[this.localStorageKeyCloseRequests] === undefined
                ? 1
                : Number(localStorage[this.localStorageKeyCloseRequests]) + 1;
        localStorage[this.localStorageKeyCloseRequests] =
            JSON.stringify(closeRequests);
        localStorage[this.localStorageKeyShowAgainDatetime] = JSON.stringify(
            showAgainDatetime.getTime(),
        );
        this.setState({
            showAgainDatetime: Number(
                localStorage[this.localStorageKeyShowAgainDatetime],
            ),
            closeRequests: Number(
                localStorage[this.localStorageKeyCloseRequests],
            ),
            timer: window.setTimeout(() => {
                this.setState({
                    timer: null,
                });
            }, milliseconds),
        });
    }

    private readonly onEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            email: e.currentTarget.value,
        });
    };

    private readonly onSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        event.preventDefault();

        try {
            await signupForEmailOffers(this.state.email);
            this.setState({
                showBanner: false,
                submitted: true,
                showAgainDatetime: null,
                timer: null,
            });
            this.setHasBeenSubmittedOrClosed();
        } catch (err) {
            this.setState({
                errors: err.response.body.email || [
                    t`An unexpected error occured.`,
                ],
            });
        }
    };

    render() {
        if (this.state.hasBeenSubmittedOrClosed) {
            return null;
        }
        if (this.state.showAgainDatetime && this.state.currentDatetime) {
            if (this.state.showAgainDatetime > this.state.currentDatetime) {
                return null;
            }
        }
        const classes = classNames({
            "l-full-width": true,
            "email-subscription": true,
        });
        // Add body class to signal open/closed state
        if (this.state.showBanner) {
            document.body.classList.remove("sticky-email-banner--closed");
            document.body.classList.add("sticky-email-banner--open");
        } else {
            document.body.classList.add("sticky-email-banner--closed");
            document.body.classList.remove("sticky-email-banner--open");
        }
        return (
            <CSSTransition
                in={this.state.showBanner}
                appear={true}
                timeout={900}
                classNames="email-subscription-"
                unmountOnExit={true}
                onEnter={() => {
                    this.onAfterOpen();
                }}
                onExited={() => {
                    this.onAfterClose();
                }}
                role="dialog"
                aria-modal={this.state.showBanner}
                aria-label="Email signup"
                id="email-subscription-modal"
            >
                <section className={classes}>
                    <div className="l-capped-width">
                        <RichText html={this.props.bannerData.content} />
                        <div className="email-sign-up">
                            <form onSubmit={this.onSubmit}>
                                <div className="floating-label using-placeholder-shown">
                                    <input
                                        placeholder=" "
                                        type="email"
                                        required={true}
                                        value={this.state.email}
                                        onChange={this.onEmailChange}
                                        id="sticky-email-input"
                                        ref={this.emailInputElem}
                                    />
                                    <span
                                        className="pseudo-placeholder"
                                        aria-hidden={true}
                                    >
                                        {t`Email Address`}
                                    </span>
                                    <label htmlFor="sticky-email-input">
                                        {t`Email Address`}
                                    </label>
                                    <input
                                        type="submit"
                                        value={
                                            !this.state.submitted
                                                ? this.props.bannerData
                                                      .submitButtonText
                                                : this.props.bannerData
                                                      .submitedButtonText
                                        }
                                    />
                                </div>
                            </form>
                            {this.state.errors.map((msg, i) => {
                                return (
                                    <p key={i} className="email-sign-up__error">
                                        {msg}
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                    <button
                        onClick={() => {
                            this.closeModal();
                        }}
                    >
                        <span className="ada-screenreader-only">Close</span>
                        <SVG
                            role="img"
                            src={iconXClose}
                            title={t`Close Icon`}
                        />
                    </button>
                </section>
            </CSSTransition>
        );
    }
}
