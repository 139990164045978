import React from "react";
import { connect } from "react-redux";

import { IProductID, ISafeHTML, IWebPageURL } from "../../../models/nominals";
import {
    IAttributeOptionGroup,
    ICompareTile,
} from "../../../models/product-compare.interfaces";
import { IViewport } from "../../../models/screen.interfaces";
import { trackCategoryPageView } from "../../../utils/analytics";
import { notEmpty } from "../../../utils/functional";
import { rootProductSelector } from "../../configurator/selectors";
import { TDispatchMapper, TStateMapper } from "../../reducers.interfaces";
import { ProductCompareGrid__ColumnCard_Component } from "../components/ProductCompareGrid__ColumnCard";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { Loaders } from "../loaders";
import { getSelectedVariant } from "../selectors";

interface IOwnProps {
    header: ISafeHTML;
    basketLink: IWebPageURL;
    financingLink: IWebPageURL;
    preselectedSize: string | null;
    sizeAttributeOptionGroup: IAttributeOptionGroup | null;
    tiles: ICompareTile[];
}

interface IReduxProps {
    priceSelectValue: string;
    pageRootProduct: IProductID | null;
    viewport: IViewport;
}

interface IDispatchProps {
    loaders: Loaders;
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IDispatchProps, IReduxProps {}

interface IState {}

class ProductCompareGrid__ColumnCard_Container extends React.Component<
    IProps,
    IState
> {
    private trackCategoryView() {
        if (!this.props.tiles) {
            return;
        }
        // Obtain SKUs for current variants
        const skus = this.props.tiles
            .map((tile) => {
                const variant = getSelectedVariant(
                    tile,
                    this.props.priceSelectValue,
                );
                return variant ? variant.skus[0] : null;
            })
            .filter(notEmpty);
        trackCategoryPageView(skus);
    }

    componentDidUpdate() {
        this.trackCategoryView();
    }

    render() {
        const urlParams = new URLSearchParams(window.location.search);
        return (
            <ProductCompareGrid__ColumnCard_Component
                header={this.props.header}
                tiles={this.props.tiles}
                basketLink={this.props.basketLink}
                financingLink={this.props.financingLink}
                sizeAttributeOptionGroup={this.props.sizeAttributeOptionGroup}
                preselectedSize={this.props.preselectedSize || null}
                priceSelectValue={this.props.priceSelectValue}
                pageRootProduct={this.props.pageRootProduct}
                urlParams={urlParams}
                viewport={this.props.viewport}
            />
        );
    }
}

const mapStateToProps: TStateMapper<
    "productcompare" | "configurator",
    IReduxProps,
    IOwnProps
> = (rootState, ownProps) => {
    const state = rootState.productcompare || defaults;
    const rootProduct = rootProductSelector(rootState.configurator);

    return {
        ...ownProps,
        viewport: rootState.common.viewport,
        priceSelectValue: state.priceSelectValue,
        pageRootProduct: rootProduct ? rootProduct.id : null,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);

    return {
        dispatchers: dispatchers,
        loaders: loaders,
    };
};

export const ProductCompareGrid__ColumnCard = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCompareGrid__ColumnCard_Container);
