import React from "react";
import { connect } from "react-redux";
import {
    TDispatchMapper,
    TStateMapper,
} from "tsi-common-react/src/apps/reducers.interfaces";
import { defaultState } from "tsi-common-react/src/apps/reviews/defaults";
import { Dispatchers } from "tsi-common-react/src/apps/reviews/dispatchers";
import {
    IReviewQueryFacets,
    ISearchFacet,
} from "tsi-common-react/src/models/reviews.interfaces";
import { parseFacetValues } from "tsi-common-react/src/utils/reviews";

import { FilterOptions } from "./FilterOptions";

const FACET_TYPE: IReviewQueryFacets = "product_id";

interface IOwnProps {}

interface IReduxProps {
    productFacet: ISearchFacet | null;
    value: string;
}

interface IDispatchProps {
    updateFilterOptionValue: Dispatchers["updateFilterOptionValue"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

const ProductFilterComponent = (props: IProps) => {
    const options = props.productFacet ? props.productFacet.options : [];
    return (
        <div className="reviews-region-stars">
            <select
                className="select select--not-styled select--no-border select--large-down-arrow review-select"
                value={props.value}
                onChange={(e) => {
                    const optID = parseInt(e.currentTarget.value, 10) || 0;
                    const isSelected = !!optID;
                    props.updateFilterOptionValue(
                        FACET_TYPE,
                        optID,
                        isSelected,
                        true,
                    );
                }}
            >
                <option value="" disabled={true}>
                    Product
                </option>
                <option value="any">Any</option>
                <FilterOptions options={options} />
            </select>
        </div>
    );
};

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    const values = parseFacetValues(state.ui.facetValues[FACET_TYPE] || "");
    return {
        productFacet:
            state.data.facets.find((facet) => facet.type === FACET_TYPE) ||
            null,
        value: values[0] || "",
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        updateFilterOptionValue: dispatchers.updateFilterOptionValue,
    };
};

export const ProductFilter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductFilterComponent);
